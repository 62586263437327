import { createFromIconfontCN, HomeOutlined, LogoutOutlined, SaveOutlined, UserOutlined, CompassOutlined, PhoneOutlined } from '@ant-design/icons';
import { Drawer } from '@material-ui/core';
import { Layout, Menu, Divider } from "antd";
import firebase from "firebase";
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SavedSearches from './pages/SavedSearches';
import SavedReports from './main/components/SavedReports';
import Profile from './Profile';

const MyIcon = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2567324_wkptrumvz8s.js',
});

const drinkTypes = [
    { key: "wineNew", label: "US Wine" },
    { key: "spiritsNew", label: "US Spirits" },
    { key: "beerNew", label: "US Beer" },
    { key: "everythingNew", label: "US Everything" },
    { key: "eu", label: "Europe Wine" },
    { key: "wineNewCa", label: "Canada Wine" },
    { key: "spiritsNewAsia", label: "Asia Spirits" },
    { key: "spiritsNewEu", label: "Europe Spirits" },
];

const { SubMenu } = Menu;

export default class Sider extends Component {

    constructor(props) {
        super(props)

    }

    toggleDrawer = (event, open) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ right: open });
    };

    toggleDrawerProfile = (event, open) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ rightProfile: open });
    };

    onCollapse = (collapsed, type) => {
        if (type == 'clickTrigger') {
            this.setState({ collapsed })
            firebase
                .database()
                .ref("users/")
                .child(firebase.auth().currentUser.uid)
                .update({ sider: collapsed });

        };
    };

    state = {
        collapsed: true
    }

    componentDidMount() {

        firebase
            .database()
            .ref(`/users/${firebase.auth().currentUser.uid}/sider`)
            .once("value")
            .then(snapshot => {
                this.setState({ collapsed: snapshot.val() });
            });

    }

    render() {
        const { collapsed } = this.state;
        const url = window.location.href;
        return (
            this.props.isPro ? <Layout.Sider className="d-print-none"
                collapsedWidth={this.props.isMobile ? "0" : "80"}
                collapsible
                collapsed={collapsed}
                breakpoint="lg"
                onCollapse={this.onCollapse}
            >

                <Menu theme="dark" mode="inline" defaultSelectedKeys={['-1']} >
                    {
                        [
                            <Menu.Item key="-1">
                                <Link to="/">
                                    <HomeOutlined />
                                    <span>Home</span>
                                </Link>
                            </Menu.Item>,
                            <Menu.Divider></Menu.Divider>,
                            this.props.Distributors && process.env.REACT_APP_SLIM != "SLIM" && [
                                <SubMenu
                                    style={{ "color": "black" }}
                                    onTitleClick={() => this.setState({ collapsed: false })}
                                    key="sub1"
                                    title={this.props.DistributorInfo ? this.props.DistributorInfo.name : "Choose a Team"}>
                                    {Object.entries(this.props.Distributors).filter(([key, value]) => key != this.props.Distributor).map(([key, value]) => <Menu.Item
                                        style={{ "color": "white" }}
                                        key={"2" + key}
                                        onClick={() => this.props.setDistributor(key)}
                                    >
                                        {value.name}
                                    </Menu.Item>)}
                                </SubMenu>,
                                this.props.Distributor && <Menu.Item
                                    key="sub2"
                                    onClick={() => {
                                        if (window.confirm("Are you sure you'd like to exit the team view? This is not common.") == true) {
                                            this.props.setDistributor(null)
                                        }

                                    }}
                                >
                                    <MyIcon type="icon-exit" />
                                    <span>
                                        Exit Company
                                    </span>
                                </Menu.Item>,
                                <Menu.Divider></Menu.Divider>],
                        ]
                    }
                    {
                        process.env.REACT_APP_DRINKTYPE == "foodNew" || process.env.REACT_APP_SLIM == "SLIM"
                            ? [
                                <Menu.Item key="1">
                                    <Link to="/s">
                                        <MyIcon type="icon-wine" />
                                        <span>Portal</span>
                                    </Link>
                                </Menu.Item>,
                            ]
                            : [
                                <Menu.Item key="0">
                                    <Link to="/chooser">
                                        <CompassOutlined />
                                        <span>
                                            {drinkTypes.find(drink => drink.key === this.props.DRINKTYPE)?.label || this.props.DRINKTYPE}
                                        </span>
                                    </Link>
                                </Menu.Item>,
                                this.props.Distributor && <Menu.Item key="2">
                                    <Link to="/dashboard">
                                        <MyIcon type="icon-dashboard" />
                                        <span>Dashboard</span>
                                    </Link>
                                </Menu.Item>,
                                <Menu.Item key="1">
                                    <Link to="/s">
                                        <MyIcon type="icon-wine" />
                                        <span>Portal</span>
                                    </Link>
                                </Menu.Item>,
                                false && <Menu.Item key="3">
                                    <Link to="/places">
                                        <MyIcon type="icon-place" />
                                        <span>Places</span>
                                    </Link>
                                </Menu.Item>,
                                false && this.props.city && <Menu.Item key="3.5">
                                    <Link to="/map">
                                        <MyIcon type="icon-map" />
                                        <span>Map</span>
                                    </Link>
                                </Menu.Item>,
                                this.props.settings.showUsData && <Menu.Item key="3.6">
                                    <Link to="/groups">
                                        <MyIcon type="icon-business" />
                                        <span>National Accounts</span>
                                    </Link>
                                </Menu.Item>,
                                this.props.settings.showUsData && <Menu.Item key="3.7">
                                    <Link to="/awards">
                                        <MyIcon type="icon-brand" />
                                        <span>Awards</span>
                                    </Link>
                                </Menu.Item>,
                                this.props.settings.showUsData && <Menu.Item key="3.8">
                                    <Link to="/suppliers">
                                        <MyIcon type="icon-boat" />
                                        <span>Suppliers</span>
                                    </Link>
                                </Menu.Item>,
                                false && this.props.Distributor && <Menu.Item key="4" >
                                    <Link to="/crm">
                                        <MyIcon type="icon-Notes" />
                                        <span>CRM</span>
                                    </Link>
                                </Menu.Item>,

                            ]
                    }
                    {
                        [
                            firebase.auth().currentUser && [<Menu.Divider></Menu.Divider>,
                            <Menu.Item key="5.5" onClick={(e) => this.toggleDrawerProfile(e, true)} >
                                {firebase.auth().currentUser.displayName ? firebase.auth().currentUser.displayName : "Update Your Name"}
                            </Menu.Item>,
                            process.env.REACT_APP_SLIM != "SLIM" && <Menu.Item key="5.6">
                                <a href="http://help.somm.ai">
                                    <PhoneOutlined />
                                    <span>Help Center</span>
                                </a>
                            </Menu.Item>,
                            //<Menu.Item key="5.7">
                            //    <a href="https://billing.stripe.com/p/login/28o8Aj7XPdSYcOQ8ww"
                            //        target="_blank" 
                            //    >
                            //        <PhoneOutlined />
                            //        <span>Billing</span>
                            //    </a>
                            //</Menu.Item>,
                            <Menu.Item key="5" onClick={(e) => this.toggleDrawer(e, true)}>
                                <SaveOutlined />
                                <span>Saved Searches</span>
                            </Menu.Item>,
                            !this.props.isPro && <Menu.Item key="5.6" >
                                <Link to="/pro">
                                    <UserOutlined />
                                    <span>Try Somm.ai Pro</span>
                                </Link>
                            </Menu.Item>,
                            <Menu.Item key="6" onClick={() => { firebase.auth().signOut() }}>
                                <LogoutOutlined />
                                <span>Logout</span>
                            </Menu.Item>],
                            <span style={collapsed ? { zIndex: 999, paddingLeft: '10%' } : { zIndex: 999, paddingLeft: '10%', bottom: 0 }}>
                                {!collapsed && (<p style={!collapsed && { paddingLeft: '25px', marginTop: '50px' }}>powered by</p>)}
                                <img className="navbar-logo" src={"/images/sommai-white.png"} alt="" style={!collapsed ? { width: '70%', paddingLeft: '20px' } : { width: '80%' }} />
                            </span>
                        ]
                    }
                </Menu>
                <React.Fragment key={"right"} >
                    <div>
                        <Drawer
                            style={{ zIndex: 999 }}
                            anchor="right" open={this.state.right} onClose={(e) => this.toggleDrawer(e, false)}>
                            <SavedSearches  {...this.props} />
                            <Divider orientation="left">Saved Reports</Divider>
                            <SavedReports  {...this.props} />
                        </Drawer>
                    </div>
                </React.Fragment>
                <React.Fragment key={"rightright"} >
                    <div>
                        <Drawer
                            style={{ zIndex: 999 }}
                            anchor="right" open={this.state.rightProfile}
                            onClose={(e) => this.toggleDrawerProfile(e, false)}
                        >
                            <Profile  {...this.props} />
                        </Drawer>
                    </div>
                </React.Fragment>
            </Layout.Sider> : null
        );
    }
}
